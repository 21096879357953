import React, {useEffect, useState} from "react";
import {Button, Card, IconButton, Select, TextField} from "@nike/eds";
import useSessionStorage from "../../hooks/useSessionStorage";
import {CaretLeft, CaretRight} from "@nike/nike-design-system-icons";
import {FlowSearchModel} from "../../model/FlowSearchModel";
import { useSearchParams } from "react-router-dom";
import useFieldOptions from "../../hooks/useFieldOptions";

const typesOptions = [
    {value: "Inbound Shipment", label: "Inbound Shipment"},
    {value: "Warehouse Entry", label: "Warehouse Entry"},
    {value: "Goods Receipt", label: "Goods Receipt"},
    {value: "Positive Adjustment", label: "Positive Adjustment"},
    {value: "Negative Adjustment", label: "Negative Adjustment"},
    {value: "Lock", label: "Lock"},
    {value: "Unlock", label: "Unlock"},
    {value: "Warehouse Removal", label: "Warehouse Removal"},
    {value: "Balancing", label: "Balancing"}
];

const statusOptions = [
    {value: "NEW", label: "New"},
    {value: "IGNORED", label: "Ignored"},
    {value: "PROCESSING", label: "Processing"},
    {value: "FAILED", label: "Failed"},
    {value: "PUBLISHED", label: "Published"},
    {value: "WAITING", label: "Waiting"},
    {value: "PROCESSED", label: "Processed"}
];

const findOptionByValue = (value, options) => {
    console.log('value', value);
    console.log('options', options);
    for (const e of options) {
        if (e.value === value) {
            return e;
        }
    }
    return null;
}

const transformToOptions = (options) => {
    if (options && options.map) {
        return options.map(e => {return {value: e, label: e}; });
    }
    return [];
};

const useSearchFieldOptions = () => {
    const shippingPoints = useFieldOptions("shippingPoint");
    const details = useFieldOptions("detail");
    const inventoryLocations = useFieldOptions("inventoryLocation");
    const shippingpointOptions = transformToOptions(shippingPoints);
    const detailOptions = transformToOptions(details);
    const inventoryLocationOptions = transformToOptions(inventoryLocations);
    return {
        shippingpointOptions,
        detailOptions,
        inventoryLocationOptions
    };
};

const emptySearchCriteria = () => {
    return {
        type: '',
        businessReference: '',
        status: '',
        startDate: '',
        endDate: '',
        flowId: '',
        detail: '',
        shippingPoint: '',
        inventoryLocation: ''
    }
};

const emptyValidation = () => {
    return {
        hasErrors: false,
        startDate: '',
        endDate: ''
    };
};

const useSearchCriteria = () => {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ searchCriteriaState, setSearchCriteria ] = useSessionStorage("searchCriteria", emptySearchCriteria());
    let searchCriteria = searchCriteriaState.value;

    const flowId = searchParams.get("flowId");
    if (flowId && flowId !== "") {
        searchCriteria  = {
            ...emptySearchCriteria(),
            flowId: flowId
        };
    }

    return {
        criteria: searchCriteria,
        restored: searchCriteriaState.restored,
        clear: () => {
            setSearchParams({flowId: ""});
            setSearchCriteria(emptySearchCriteria());
        },
        update: (newState) => {
            setSearchCriteria(newState);
        }
    };
}

function FlowSearchForm(props) {
    const searchFieldOptions = useSearchFieldOptions();
    const searchCriteriaState = useSearchCriteria();
    const searchCriteria = searchCriteriaState.criteria;
    const setSearchCriteria = searchCriteriaState.update;
    const [ validationError, setValidationError ] = useState(emptyValidation());
    const [showSearch, setShowSearch] = useState(false);
    

    const validateSearchCriteriaDates = () => {
        const errors = emptyValidation();
        if (searchCriteria.startDate !== "" && searchCriteria.endDate !== "" && (searchCriteria.startDate > searchCriteria.endDate)) {
            errors.startDate = "The start date should be before the end date";
            errors.endDate = "The end date should be after the start date";
            errors.hasErrors = true;
        }
        setValidationError(errors);
    }

    useEffect(() => {
        validateSearchCriteriaDates()
    }, [searchCriteria.startDate, searchCriteria.endDate]);


    const clearSearchCriteria = () => {
        searchCriteriaState.clear();
    }

    const toggleShowSearch = () => {
        setShowSearch(!showSearch);
    }

    const SearchButton = () => {
        return (
            <Button
                variant="ghost"
                beforeSlot={!showSearch ? <CaretLeft/> : <CaretRight/>}
                onClick={toggleShowSearch}
                size={"small"}
            >{!showSearch ? 'Filter' : ''}
            </Button>
        );
    }

    const typeChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            type: event !== null ? event.value : ''
        });

    }
    const businessReferenceChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            businessReference: event.target.value
        });
    }

    const statusChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            status: event !== null ? event.value : ''
        });
    }
    const startDateChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            startDate: event.target.value
        });
    }
    const endDateChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            endDate: event.target.value
        });
    }
    const detailChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            detail: event != null ? event.value : ''
        });
    }
    const shippingPointChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            shippingPoint: event != null ? event.value : ''
        });
    }
    const inventoryLocationChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            inventoryLocation: event != null ? event.value : ''
        });
    }

    const doSearch = (restore = false) => {
        if (validationError.hasErrors) {
            return;
        }

        let searchFlowData: FlowSearchModel = {
            type: searchCriteria.type,
            businessReference: searchCriteria.businessReference,
            startDate: new Date(searchCriteria.startDate),
            endDate: new Date(searchCriteria.endDate),
            status: searchCriteria.status,
            flowId: searchCriteria.flowId,
            detail: searchCriteria.detail,
            shippingPoint: searchCriteria.shippingPoint,
            inventoryLocation: searchCriteria.inventoryLocation
        }
        props.onSearch(searchFlowData, restore);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        doSearch();
    }

    useEffect(() => {
        if (searchCriteriaState.restored) {
            doSearch(true);
        }
    }, [ searchCriteriaState.restored ]);


    const flowIdSet = searchCriteria.flowId != undefined && searchCriteria.flowId !== '';

    const columnOneStyle = { width: 350 };

    return (
        <div>
        {!showSearch ? (
                <div className="eds-grid eds-grid--m-cols-6 eds-gap--8">
                    <div className="eds-grid--m-col-6 eds-grid--m-row-1 eds-grid--item-align-start eds-grid--item-justify-end eds-spacing--p-12"
                        style={{"display": "flex"}}>
                        <Card className="eds-elevation--4">
                            <SearchButton />
                        </Card>
                    </div>
                </div>
            ) :
                <div style={{backgroundColor: "white"}}>
                    <form onSubmit={submitHandler}>
                        <div className="eds-grid eds-grid--m-cols-4 eds-gap--8">
                            <Card className="eds-grid--m-col-1 eds-grid--m-rows-5 eds-grid--item-align-start eds-spacing--p-4">

                                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                    <TextField onChange={businessReferenceChangeHandler}
                                               id="businessReferenceSearchField"
                                               type="text"
                                               hasErrors={false}
                                               label="Business Reference"
                                               value={searchCriteria.businessReference}
                                               style={columnOneStyle}
                                               disabled={flowIdSet}
                                    />
                                </Card>
                                <Card className="eds-grid--m-row-1 eds-spacing--p-4" >
                                    <div style={columnOneStyle}>
                                        <Select onChange={typeChangeHandler}
                                                value={findOptionByValue(searchCriteria.type, typesOptions)}
                                                isClearable={true}
                                                id="typeSelect"
                                                options={typesOptions}
                                                label="Type"
                                                isDisabled={flowIdSet}
                                        />
                                    </div>
                                </Card>
                                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                    <div style={columnOneStyle}>
                                        <Select onChange={detailChangeHandler}
                                                value={findOptionByValue(searchCriteria.detail, searchFieldOptions.detailOptions)}
                                                isClearable={true}
                                                id="detailSearchField"
                                                label="Detail"
                                                options={searchFieldOptions.detailOptions}
                                                isDisabled={flowIdSet}
                                        />
                                    </div>
                                </Card>
                            </Card>
                            <Card
                                className="eds-grid--m-col-1 eds-grid--m-rows-5 eds-grid--item-align-start eds-spacing--p-4">
                                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                    <div style={{width: 300}}>
                                        <Select onChange={shippingPointChangeHandler}
                                                value={findOptionByValue(searchCriteria.shippingPoint, searchFieldOptions.shippingpointOptions)}
                                                isClearable={true}
                                                id="shippingPointSelect"
                                                label="Shipping Point"
                                                options={searchFieldOptions.shippingpointOptions}
                                                isDisabled={flowIdSet}
                                        />
                                    </div>
                                </Card>
                                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                    <div style={{width: 300}}>
                                        <Select onChange={inventoryLocationChangeHandler}
                                                value={findOptionByValue(searchCriteria.inventoryLocation, searchFieldOptions.inventoryLocationOptions)}
                                                isClearable={true}
                                                id="inventoryLocationSelect"
                                                label="Inventory Location"
                                                options={searchFieldOptions.inventoryLocationOptions}
                                                isDisabled={flowIdSet}
                                        />
                                    </div>
                                </Card>
                                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                    <div style={{width: 300}}>
                                        <Select onChange={statusChangeHandler}
                                                value={findOptionByValue(searchCriteria.status, statusOptions)}
                                                isClearable={true}
                                                id="statusSelect"
                                                label="Status"
                                                options={statusOptions}
                                                isDisabled={flowIdSet}
                                        />
                                    </div>
                                </Card>
                            </Card>
                            <Card
                                className="eds-grid--m-col-1 eds-grid--m-rows-1 eds-grid--item-align-start eds-spacing--p-4">
                                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                    <div style={{width: 300}}>
                                        <TextField
                                            onChange={startDateChangeHandler}
                                            value={searchCriteria.startDate}
                                            id="id"
                                            type="datetime-local"
                                            hasErrors={validationError.startDate !== ''}
                                            errorMessage={validationError.startDate}
                                            label="Enter a start date"
                                            disabled={flowIdSet}
                                        />
                                    </div>
                                </Card>
                                <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                    <div style={{width: 300}}>
                                        <TextField
                                            onChange={endDateChangeHandler}
                                            value={searchCriteria.endDate}
                                            id="id"
                                            type="datetime-local"
                                            hasErrors={validationError.endDate !== ''}
                                            errorMessage={validationError.endDate}
                                            label="Enter an end date"
                                            disabled={flowIdSet}
                                        />
                                    </div>
                                </Card>
                                { flowIdSet
                                    ? <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                                        <div style={{width: 300}}>
                                            <TextField id="flowIdSearchField"
                                                       type="text"
                                                       hasErrors={false}
                                                       label="Flow id"
                                                       value={searchCriteria.flowId}
                                                       style={{width: 300}}
                                                       disabled
                                            />
                                        </div>
                                    </Card>
                                    : undefined}
                            </Card>
                            <Card className="eds-grid--m-col-1 eds-grid--m-row-1 eds-grid--item-align-start eds-grid--item-justify-end eds-spacing--p-12">
                                <div className="eds-grid eds-grid--m-cols-6 eds-gap--8">
                                    <Card className="eds-grid--m-col-6 eds-grid--m-row-1 eds-grid--item-align-start eds-grid--item-justify-end">
                                        <SearchButton />
                                    </Card>
                                </div>

                                <Card className="eds-grid--m-row-1 eds-spacing--mt-48">
                                    <Button
                                        variant="primary"
                                        type={"submit"}
                                        size={"small"} disabled={flowIdSet} >Apply Filter</Button>
                                    <Button className="eds-spacing--ml-8"
                                        onClick={clearSearchCriteria}
                                        variant="secondary"
                                        size={"small"}>Clear All</Button>
                                </Card>




                            </Card>
                        </div>
                    </form>
                </div>
        }
        </div>
    );
}

export {FlowSearchForm}
